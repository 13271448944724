/**
 * Trumbowyg v2.0.0-beta.4 - A lightweight WYSIWYG editor
 * Default stylesheet for Trumbowyg editor
 * ------------------------
 * @link http://alex-d.github.io/Trumbowyg
 * @license MIT
 * @author Alexandre Demode (Alex-D)
 *         Twitter : @AlexandreDemode
 *         Website : alex-d.fr
 */

.trumbowyg-box *, .trumbowyg-box *::before, .trumbowyg-box *::after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box; }

.trumbowyg-box, .trumbowyg-editor {
  display: block;
  position: relative;
  border: 1px solid #DDD;
  width: 96%;
  min-height: 300px;
  margin: 17px auto; }

.trumbowyg-box .trumbowyg-editor {
  margin: 0 auto; }

.trumbowyg-box.trumbowyg-fullscreen {
  background: #FEFEFE; }

.trumbowyg-editor, .trumbowyg-textarea {
  position: relative;
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 1% 2%;
  min-height: 300px;
  width: 100%;
  border-style: none;
  resize: none;
  outline: none; }

.trumbowyg-box-blur .trumbowyg-editor * {
  color: transparent !important;
  text-shadow: 0 0 7px #333; }
.trumbowyg-box-blur .trumbowyg-editor img {
  opacity: 0.2; }

.trumbowyg-textarea {
  position: relative;
  display: block;
  overflow: auto;
  border: none;
  white-space: normal;
  font-size: 14px;
  font-family: "Inconsolata", "Consolas", "Courier", "Courier New", sans-serif;
  line-height: 18px; }

.trumbowyg-box.trumbowyg-editor-visible .trumbowyg-textarea {
  position: absolute;
  top: 37px;
  height: 1px !important;
  width: 25%;
  min-height: 0 !important;
  padding: 0 !important;
  background: none;
  opacity: 0; }

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-textarea {
  display: block; }
.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-editor {
  display: none; }

.trumbowyg-editor[contenteditable=true]:empty:before {
  content: attr(placeholder);
  color: #999; }

.trumbowyg-button-pane {
  position: relative;
  width: 100%;
  background: #ecf0f1;
  border-bottom: 1px solid #d7e0e2;
  margin: 0;
  padding: 0;
  list-style-type: none;
  line-height: 10px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .trumbowyg-button-pane li {
    display: inline-block;
    text-align: center;
    overflow: hidden;
    padding: 0 !important; }
    .trumbowyg-button-pane li.trumbowyg-separator {
      width: 1px;
      background: #d7e0e2;
      margin: 0 5px;
      height: 35px; }
  .trumbowyg-button-pane.trumbowyg-disable li:not(.trumbowyg-not-disable) button:not(.trumbowyg-active) {
    opacity: 0.2;
    cursor: default; }
  .trumbowyg-button-pane.trumbowyg-disable li.trumbowyg-separator {
    background: #e3e9eb; }
  .trumbowyg-button-pane li button {
    padding: 1px 6px !important; }
  .trumbowyg-button-pane:not(.trumbowyg-disable) li button:hover, .trumbowyg-button-pane:not(.trumbowyg-disable) li button:focus, .trumbowyg-button-pane li button.trumbowyg-active, .trumbowyg-button-pane li.trumbowyg-not-disable button:hover, .trumbowyg-button-pane li.trumbowyg-not-disable button:focus {
    background-color: #FFF;
    outline: none; }
  .trumbowyg-button-pane li .trumbowyg-open-dropdown:after {
    display: block;
    content: " ";
    position: absolute;
    top: 25px;
    right: 3px;
    height: 0;
    width: 0;
    border: 3px solid transparent;
    border-top-color: #555; }
  .trumbowyg-button-pane .trumbowyg-buttons-right {
    float: right;
    width: auto; }
    .trumbowyg-button-pane .trumbowyg-buttons-right button {
      float: left; }

.trumbowyg-dropdown {
  width: 200px;
  border: 1px solid #ecf0f1;
  padding: 5px 0;
  border-top: none;
  background: #FFF;
  margin-left: -1px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
          box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px; }
  .trumbowyg-dropdown button {
    display: block;
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-decoration: none;
    background: #FFF;
    padding: 0 14px;
    color: #333 !important;
    border: none;
    cursor: pointer;
    text-align: left;
    font-size: 15px;
    -webkit-transition: all 0.15s;
            transition: all 0.15s; }
    .trumbowyg-dropdown button:hover, .trumbowyg-dropdown button:focus {
      background: #ecf0f1; }

/* Modal box */
.trumbowyg-modal {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -260px;
  width: 520px;
  height: 350px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

.trumbowyg-modal-box {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -250px;
  width: 500px;
  padding-bottom: 45px;
  z-index: 1;
  background-color: #FFF;
  text-align: center;
  font-size: 14px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
          box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .trumbowyg-modal-box .trumbowyg-modal-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px;
    padding: 15px 0 13px;
    display: block;
    border-bottom: 1px solid #EEE;
    color: #333;
    background: #fbfcfc; }
  .trumbowyg-modal-box .trumbowyg-progress {
    width: 100%;
    background: #F00;
    height: 3px;
    position: absolute;
    top: 58px; }
    .trumbowyg-modal-box .trumbowyg-progress .trumbowyg-progress-bar {
      background: #2BC06A;
      height: 100%;
      -webkit-transition: width 0.15s linear;
              transition: width 0.15s linear; }
  .trumbowyg-modal-box label {
    display: block;
    position: relative;
    margin: 15px 12px;
    height: 27px;
    line-height: 27px;
    overflow: hidden; }
    .trumbowyg-modal-box label .trumbowyg-input-infos {
      display: block;
      text-align: left;
      height: 25px;
      line-height: 25px;
      -webkit-transition: all 0.15;
              transition: all 0.15; }
      .trumbowyg-modal-box label .trumbowyg-input-infos span {
        display: block;
        color: #69878e;
        background-color: #fbfcfc;
        border: 1px solid #DEDEDE;
        padding: 0 7px;
        width: 137px; }
      .trumbowyg-modal-box label .trumbowyg-input-infos span.trumbowyg-msg-error {
        color: #e74c3c; }
    .trumbowyg-modal-box label.trumbowyg-input-error input, .trumbowyg-modal-box label.trumbowyg-input-error textarea {
      border: 1px solid #e74c3c; }
    .trumbowyg-modal-box label.trumbowyg-input-error .trumbowyg-input-infos {
      margin-top: -27px; }
    .trumbowyg-modal-box label input {
      position: absolute;
      top: 0;
      right: 0;
      height: 27px;
      line-height: 27px;
      border: 1px solid #DEDEDE;
      background: transparent;
      font-size: 14px;
      width: 340px;
      padding: 0 7px;
      -webkit-transition: all 0.15s;
              transition: all 0.15s; }
      .trumbowyg-modal-box label input:hover, .trumbowyg-modal-box label input:focus {
        outline: none;
        border: 1px solid #95a5a6; }
      .trumbowyg-modal-box label input:focus {
        background: rgba(230, 230, 255, 0.1); }
  .trumbowyg-modal-box .error {
    margin-top: 25px;
    display: block;
    color: red; }
  .trumbowyg-modal-box .trumbowyg-modal-button {
    position: absolute;
    bottom: 10px;
    right: 0;
    text-decoration: none;
    color: #FFF;
    display: block;
    width: 100px;
    height: 35px;
    line-height: 33px;
    margin: 0 10px;
    background-color: #333;
    border: none;
    border-top: none;
    cursor: pointer;
    font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif;
    font-size: 16px;
    -webkit-transition: all 0.15s;
            transition: all 0.15s; }
    .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit {
      right: 110px;
      background: #2bc069; }
      .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:hover, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:focus {
        background: #40d47d;
        outline: none; }
      .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:active {
        background: #25a259; }
    .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset {
      color: #555;
      background: #e6e6e6; }
      .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:hover, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:focus {
        background: #fbfbfb;
        outline: none; }
      .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:active {
        background: #d4d4d4; }

.trumbowyg-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  left: 0;
  display: none; }

/**
 * Fullscreen
 */
body.trumbowyg-body-fullscreen {
  overflow: hidden; }

.trumbowyg-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99999; }
  .trumbowyg-fullscreen.trumbowyg-box, .trumbowyg-fullscreen .trumbowyg-editor {
    border: none; }
  .trumbowyg-fullscreen .trumbowyg-overlay {
    height: 100% !important; }

/*
 * Reset for resetCss option
 */
.trumbowyg-editor object, .trumbowyg-editor embed, .trumbowyg-editor video, .trumbowyg-editor img {
  width: auto;
  max-width: 100%; }
.trumbowyg-editor video, .trumbowyg-editor img {
  height: auto; }
.trumbowyg-editor img {
  cursor: move; }
.trumbowyg-editor.trumbowyg-reset-css {
  background: #FEFEFE !important;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.45em !important;
  white-space: normal !important;
  color: #333; }
  .trumbowyg-editor.trumbowyg-reset-css a {
    color: #15c !important;
    text-decoration: underline !important; }
  .trumbowyg-editor.trumbowyg-reset-css div, .trumbowyg-editor.trumbowyg-reset-css p, .trumbowyg-editor.trumbowyg-reset-css ul, .trumbowyg-editor.trumbowyg-reset-css ol, .trumbowyg-editor.trumbowyg-reset-css blockquote {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    background: none !important;
    margin: 0 !important;
    margin-bottom: 15px !important;
    line-height: 1.4em !important;
    font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
    font-size: 14px !important;
    border: none; }
  .trumbowyg-editor.trumbowyg-reset-css iframe, .trumbowyg-editor.trumbowyg-reset-css object, .trumbowyg-editor.trumbowyg-reset-css hr {
    margin-bottom: 15px !important; }
  .trumbowyg-editor.trumbowyg-reset-css blockquote {
    margin-left: 32px !important;
    font-style: italic !important;
    color: #555; }
  .trumbowyg-editor.trumbowyg-reset-css ul, .trumbowyg-editor.trumbowyg-reset-css ol {
    padding-left: 20px !important; }
  .trumbowyg-editor.trumbowyg-reset-css ul ul, .trumbowyg-editor.trumbowyg-reset-css ol ol, .trumbowyg-editor.trumbowyg-reset-css ul ol, .trumbowyg-editor.trumbowyg-reset-css ol ul {
    border: none;
    margin: 2px !important;
    padding: 0 !important;
    padding-left: 24px !important; }
  .trumbowyg-editor.trumbowyg-reset-css hr {
    display: block;
    height: 1px;
    border: none;
    border-top: 1px solid #CCC; }
  .trumbowyg-editor.trumbowyg-reset-css h1, .trumbowyg-editor.trumbowyg-reset-css h2, .trumbowyg-editor.trumbowyg-reset-css h3, .trumbowyg-editor.trumbowyg-reset-css h4 {
    color: #111;
    background: none;
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold; }
  .trumbowyg-editor.trumbowyg-reset-css h1 {
    font-size: 32px !important;
    line-height: 38px !important;
    margin-bottom: 20px !important; }
  .trumbowyg-editor.trumbowyg-reset-css h2 {
    font-size: 26px !important;
    line-height: 34px !important;
    margin-bottom: 15px !important; }
  .trumbowyg-editor.trumbowyg-reset-css h3 {
    font-size: 22px !important;
    line-height: 28px !important;
    margin-bottom: 7px !important; }
  .trumbowyg-editor.trumbowyg-reset-css h4 {
    font-size: 16px !important;
    line-height: 22px !important;
    margin-bottom: 7px !important; }

/*
 * Black theme
 */
.trumbowyg-black .trumbowyg-button-pane {
  background-color: #222;
  border-bottom-color: #343434; }
  .trumbowyg-black .trumbowyg-button-pane li.trumbowyg-separator {
    background-color: #343434; }
  .trumbowyg-black .trumbowyg-button-pane.trumbowyg-disable li.trumbowyg-separator {
    background-color: #2a2a2a; }
  .trumbowyg-black .trumbowyg-button-pane:not(.trumbowyg-disable) li button:hover, .trumbowyg-black .trumbowyg-button-pane:not(.trumbowyg-disable) li button:focus, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-active, .trumbowyg-black .trumbowyg-button-pane li.trumbowyg-not-disable button:hover, .trumbowyg-black .trumbowyg-button-pane li.trumbowyg-not-disable button:focus {
    background-color: #555; }
  .trumbowyg-black .trumbowyg-button-pane li .trumbowyg-open-dropdown:after {
    border-top-color: #fff; }
.trumbowyg-black .trumbowyg-dropdown {
  border-color: #222;
  background: #222; }
  .trumbowyg-black .trumbowyg-dropdown button {
    background: #222;
    color: #fff; }
    .trumbowyg-black .trumbowyg-dropdown button:hover, .trumbowyg-black .trumbowyg-dropdown button:focus {
      background: #555; }
.trumbowyg-black .trumbowyg-modal-box {
  background-color: #222; }
  .trumbowyg-black .trumbowyg-modal-box .trumbowyg-modal-title {
    border-bottom: 1px solid #555;
    color: #fff;
    background: #3c3c3c; }
  .trumbowyg-black .trumbowyg-modal-box label {
    display: block;
    position: relative;
    margin: 15px 12px;
    height: 27px;
    line-height: 27px;
    overflow: hidden; }
    .trumbowyg-black .trumbowyg-modal-box label .trumbowyg-input-infos span {
      color: #eee;
      background-color: #2f2f2f;
      border-color: #222; }
    .trumbowyg-black .trumbowyg-modal-box label .trumbowyg-input-infos span.trumbowyg-msg-error {
      color: #e74c3c; }
    .trumbowyg-black .trumbowyg-modal-box label.trumbowyg-input-error input, .trumbowyg-black .trumbowyg-modal-box label.trumbowyg-input-error textarea {
      border-color: #e74c3c; }
    .trumbowyg-black .trumbowyg-modal-box label input {
      border-color: #222;
      color: #eee;
      background: #333; }
      .trumbowyg-black .trumbowyg-modal-box label input:hover, .trumbowyg-black .trumbowyg-modal-box label input:focus {
        border-color: #95a5a6; }
      .trumbowyg-black .trumbowyg-modal-box label input:focus {
        background-color: rgba(230, 230, 255, 0.1); }
.trumbowyg-black .trumbowyg-overlay {
  background-color: rgba(0, 0, 0, 0.5); }

/*
 * Buttons icons
 */
.trumbowyg-button-pane li button {
  display: block;
  position: relative;
  text-indent: -9999px;
  width: 35px;
  height: 35px;
  overflow: hidden;
  background: transparent url("../img/icons-black.png") no-repeat;
  border: none;
  cursor: pointer;
  -webkit-transition: background-color 0.15s, background-image 0.15s, opacity 0.15s;
          transition: background-color 0.15s, background-image 0.15s, opacity 0.15s;
  /* English and others */ }
  .trumbowyg-button-pane li button.trumbowyg-viewHTML-button {
    background-position: 5px -570px; }
  .trumbowyg-button-pane li button.trumbowyg-formatting-button {
    background-position: 5px -120px; }
  .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-button-pane li button.trumbowyg-strong-button {
    background-position: 5px -45px; }
  .trumbowyg-button-pane li button.trumbowyg-italic-button, .trumbowyg-button-pane li button.trumbowyg-em-button {
    background-position: 5px -270px; }
  .trumbowyg-button-pane li button.trumbowyg-underline-button {
    background-position: 5px -495px; }
  .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-button-pane li button.trumbowyg-del-button {
    background-position: 5px -470px; }
  .trumbowyg-button-pane li button.trumbowyg-link-button {
    background-position: 5px -345px; }
  .trumbowyg-button-pane li button.trumbowyg-insertImage-button {
    background-position: 5px -245px; }
  .trumbowyg-button-pane li button.trumbowyg-justifyLeft-button {
    background-position: 5px -320px; }
  .trumbowyg-button-pane li button.trumbowyg-justifyCenter-button {
    background-position: 5px -70px; }
  .trumbowyg-button-pane li button.trumbowyg-justifyRight-button {
    background-position: 5px -420px; }
  .trumbowyg-button-pane li button.trumbowyg-justifyFull-button {
    background-position: 5px 5px; }
  .trumbowyg-button-pane li button.trumbowyg-unorderedList-button {
    background-position: 5px -520px; }
  .trumbowyg-button-pane li button.trumbowyg-orderedList-button {
    background-position: 5px -370px; }
  .trumbowyg-button-pane li button.trumbowyg-horizontalRule-button {
    background-position: 5px -220px; }
  .trumbowyg-button-pane li button.trumbowyg-removeformat-button {
    background-position: 5px -395px; }
  .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
    background-position: 5px -170px; }
  .trumbowyg-button-pane li button.trumbowyg-close-button {
    background-position: 5px -95px; }

.trumbowyg-fullscreen .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
  background-position: 5px -145px; }

.trumbowyg-button-pane li:first-child button {
  margin-left: 6px; }
.trumbowyg-button-pane li:last-child button {
  margin-right: 6px; }

/* French */
.trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strong-button {
  background-position: 5px -195px; }
.trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-underline-button {
  background-position: 5px -445px; }
.trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-del-button {
  background-position: 5px -295px; }

.trumbowyg-black {
  /* French */ }
  .trumbowyg-black .trumbowyg-button-pane li button {
    background-image: url("../img/icons-white.png");
    /* English and others */ }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-viewHTML-button {
      background-position: 5px -570px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-formatting-button {
      background-position: 5px -120px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-strong-button {
      background-position: 5px -45px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-italic-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-em-button {
      background-position: 5px -270px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-underline-button {
      background-position: 5px -495px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-del-button {
      background-position: 5px -470px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-link-button {
      background-position: 5px -345px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-insertImage-button {
      background-position: 5px -245px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyLeft-button {
      background-position: 5px -320px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyCenter-button {
      background-position: 5px -70px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyRight-button {
      background-position: 5px -420px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyFull-button {
      background-position: 5px 5px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-unorderedList-button {
      background-position: 5px -520px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-orderedList-button {
      background-position: 5px -370px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-horizontalRule-button {
      background-position: 5px -220px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-removeformat-button {
      background-position: 5px -395px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
      background-position: 5px -170px; }
    .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-close-button {
      background-position: 5px -95px; }
  .trumbowyg-black .trumbowyg-fullscreen .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
    background-position: 5px -145px; }
  .trumbowyg-black .trumbowyg-button-pane li:first-child button {
    margin-left: 6px; }
  .trumbowyg-black .trumbowyg-button-pane li:last-child button {
    margin-right: 6px; }
  .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strong-button {
    background-position: 5px -195px; }
  .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-underline-button {
    background-position: 5px -445px; }
  .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-del-button {
    background-position: 5px -295px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 4/3), only screen and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  /* French */
  /* Black theme */
  .trumbowyg-button-pane li button {
    -webkit-background-size: 25px 600px !important;
            background-size: 25px 600px !important;
    background-image: url("../img/icons-black-2x.png") !important;
    /* English and others */ }
    .trumbowyg-button-pane li button.trumbowyg-viewHTML-button {
      background-position: 5px -570px; }
    .trumbowyg-button-pane li button.trumbowyg-formatting-button {
      background-position: 5px -120px; }
    .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-button-pane li button.trumbowyg-strong-button {
      background-position: 5px -45px; }
    .trumbowyg-button-pane li button.trumbowyg-italic-button, .trumbowyg-button-pane li button.trumbowyg-em-button {
      background-position: 5px -270px; }
    .trumbowyg-button-pane li button.trumbowyg-underline-button {
      background-position: 5px -495px; }
    .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-button-pane li button.trumbowyg-del-button {
      background-position: 5px -470px; }
    .trumbowyg-button-pane li button.trumbowyg-link-button {
      background-position: 5px -345px; }
    .trumbowyg-button-pane li button.trumbowyg-insertImage-button {
      background-position: 5px -245px; }
    .trumbowyg-button-pane li button.trumbowyg-justifyLeft-button {
      background-position: 5px -320px; }
    .trumbowyg-button-pane li button.trumbowyg-justifyCenter-button {
      background-position: 5px -70px; }
    .trumbowyg-button-pane li button.trumbowyg-justifyRight-button {
      background-position: 5px -420px; }
    .trumbowyg-button-pane li button.trumbowyg-justifyFull-button {
      background-position: 5px 5px; }
    .trumbowyg-button-pane li button.trumbowyg-unorderedList-button {
      background-position: 5px -520px; }
    .trumbowyg-button-pane li button.trumbowyg-orderedList-button {
      background-position: 5px -370px; }
    .trumbowyg-button-pane li button.trumbowyg-horizontalRule-button {
      background-position: 5px -220px; }
    .trumbowyg-button-pane li button.trumbowyg-removeformat-button {
      background-position: 5px -395px; }
    .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
      background-position: 5px -170px; }
    .trumbowyg-button-pane li button.trumbowyg-close-button {
      background-position: 5px -95px; }
  .trumbowyg-fullscreen .trumbowyg-button-pane li a.trumbowyg-fullscreen-button {
    background-position: 5px -145px; }
  .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strong-button {
    background-position: 5px -195px; }
  .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-underline-button {
    background-position: 5px -445px; }
  .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-del-button {
    background-position: 5px -295px; }
  .trumbowyg-black {
    /* French */ }
    .trumbowyg-black .trumbowyg-button-pane li button {
      -webkit-background-size: 25px 600px !important;
              background-size: 25px 600px !important;
      background-image: url("../img/icons-white-2x.png") !important;
      /* English and others */ }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-viewHTML-button {
        background-position: 5px -570px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-formatting-button {
        background-position: 5px -120px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-strong-button {
        background-position: 5px -45px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-italic-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-em-button {
        background-position: 5px -270px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-underline-button {
        background-position: 5px -495px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-del-button {
        background-position: 5px -470px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-link-button {
        background-position: 5px -345px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-insertImage-button {
        background-position: 5px -245px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyLeft-button {
        background-position: 5px -320px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyCenter-button {
        background-position: 5px -70px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyRight-button {
        background-position: 5px -420px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-justifyFull-button {
        background-position: 5px 5px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-unorderedList-button {
        background-position: 5px -520px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-orderedList-button {
        background-position: 5px -370px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-horizontalRule-button {
        background-position: 5px -220px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-removeformat-button {
        background-position: 5px -395px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-fullscreen-button {
        background-position: 5px -170px; }
      .trumbowyg-black .trumbowyg-button-pane li button.trumbowyg-close-button {
        background-position: 5px -95px; }
    .trumbowyg-black .trumbowyg-fullscreen .trumbowyg-button-pane li a.trumbowyg-fullscreen-button {
      background-position: 5px -145px; }
    .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-bold-button, .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strong-button {
      background-position: 5px -195px; }
    .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-underline-button {
      background-position: 5px -445px; }
    .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-strikethrough-button, .trumbowyg-black .trumbowyg-fr .trumbowyg-button-pane li button.trumbowyg-del-button {
      background-position: 5px -295px; } }
